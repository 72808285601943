import React from 'react';

import Link from '@components/Link';

// import { SearchClubNumberedMeetingFragment } from '@domain/clubs';
import { Meeting } from '../ClubSearchList';

import {
  // getMeetingTypeLabel,
  getMeetingTypeTranslation,
} from '@use-cases/clubs';

import { trimClubFullName } from '@utils/club-name';
import { getDayLabel } from '@utils/getDayLabel';

import {
  // MEETING_TYPE_MEETING,
  MEETING_TYPE_IN_PERSON,
  MEETING_TYPE_ONLINE,
  MEETING_TYPE_PHYSICAL,
} from '@domui-domain/search/constants';
import { getClubTypeName } from '@domui-utils/getClubTypeName';

import { useTranslation } from '@external/react-i18next';
import { useAnalytics } from '@hooks/analytics';

import { DIS } from '@typings/dis';
import { Enum, From, mapEnum } from '@typings/map-enum';

type InfoBoxDetailsProps = {
  fullName: string;
  type: string;
  id: string;
  meetings: Meeting[];
};

const InfoBoxDetails: React.FC<InfoBoxDetailsProps> = ({
  fullName,
  type,
  meetings,
  id,
}) => {
  const { fireTrackingEvent } = useAnalytics();
  const { t } = useTranslation();
  const clubPath = `/club/${id}/details`;

  const clubMeeting = meetings
    ? meetings.map(meeting => {
        const {
          url,
          weekday,
          description,
          languageName,
          time,
          latitude,
          longitude,
        } = meeting;

        const localizedMeetingType = getMeetingTypeTranslation(t, type);
        const isPhysical =
          type === MEETING_TYPE_PHYSICAL || type === MEETING_TYPE_IN_PERSON;

        return (
          <div
            key={`${latitude} ${longitude}`}
            data-label="SearchResult"
            className="flex flex-col w-full mt-0"
          >
            <p className="text-small">
              {description}
              <br />
              {t(
                'club-search.meetings.description-physical',
                'Meets {{ day }}, {{ time }}',
                {
                  day: getDayLabel(t, String(weekday)),
                  time,
                }
              )}

              {languageName !== null && languageName}
            </p>
            {url && !isPhysical && (
              <p className="text-small">{localizedMeetingType}</p>
            )}
          </div>
        );
      })
    : [];

  const firstMeeting = meetings?.find(
    meeting => meeting.city || meeting.state || meeting.country
  );

  // const { xRiAddress, categories } = firstMeeting || {
  //   xRiAddress: null,
  //   categories: null,
  // };

  const ClubNameType = type
    ? getClubTypeName(t, mapEnum(From.DIS, Enum.ClubType, type as DIS.ClubType))
    : '';

  const fullNameWithType = `${trimClubFullName(fullName)} (${ClubNameType})`;

  const city = firstMeeting?.city || '';
  const state = firstMeeting?.state ? `, ${firstMeeting.state}` : '';
  const country = firstMeeting?.country ? `, ${firstMeeting.country}` : '';

  const isOnline = type ? type === MEETING_TYPE_ONLINE : false;

  const trackClick = (destination: string) => {
    fireTrackingEvent('searchResultClicked', {
      searchResultDestination: destination,
      searchType: 'Club Search',
      searchResultIndex: 0,
    });
  };

  return (
    <div data-label="SearchResult" className="flex flex-col w-3/4 pt-4">
      <div className="pl-4">
        <h3 className="mb-2">
          <Link
            role="link"
            to={clubPath}
            onClick={() => trackClick(clubPath)}
            target="_blank"
            useExternalLink
          >
            {fullNameWithType}
          </Link>
        </h3>

        {firstMeeting && !isOnline && (
          <p className="text-small font-bold">{`${city}${state}${country}`}</p>
        )}
        {clubMeeting}
      </div>
    </div>
  );
};

export default InfoBoxDetails;
