import React, { useEffect } from 'react';

import { Formik, FormikHelpers } from 'formik';
import { isEmpty, omit, pickBy } from 'lodash';

import { useNotifications } from '@use-cases/notifications';

import { addLocalisationToPath } from '@utils/localized-navigate';

import { ClubSearchFormValues } from '@domui-domain/clubs';
import { useFetchLanguages } from '@domui-hooks/useFetchLanguages';
import {
  getOrganizationBase,
  withLocationKeywordFallback,
} from '@domui-use-cases/clubs';
import {
  addQueryParamsToURL,
  getClubParamsFromURL,
  validateClubParamsFromURL,
} from '@domui-use-cases/districts';
import { getMeetingLocationRange } from '@domui-utils/geography';

import { useTranslation } from '@external/react-i18next';

import { OrganizationBase } from '@typings/operations';

export interface ClubSearchFormikProps {
  onSubmit: (value: ClubSearchFormValues) => void;
  setDragged: (flag: boolean) => void;
  setResetFlag: (flag: boolean) => void;
  meetingLocation: string;
}

const ClubSearchFormik: React.FC<ClubSearchFormikProps> = ({
  onSubmit,
  setDragged,
  setResetFlag,
  meetingLocation,
  children,
}) => {
  const { t } = useTranslation();
  const { addError, clearNotification } = useNotifications();
  const { data, fetchLanguages } = useFetchLanguages();

  useEffect(() => {
    fetchLanguages();
  }, []);
  const noFiltersError = t(
    'site-search.error.no-search-term',
    'Please enter a search term.'
  );

  const itemsToExclude = ['meetingLocationRange', 'distance', 'distanceUnits'];

  const refreshSearch = (input: ClubSearchFormValues) => {
    const numberOfFilters = Object.keys(
      pickBy(omit(input, itemsToExclude), Boolean)
    ).length;

    if (numberOfFilters === 0) {
      addError(noFiltersError, { id: 'form.no-filters-error' });
    } else {
      clearNotification('form.no-filters-error');
    }
  };

  const initialValues: ClubSearchFormValues = {
    meetingLocationRange: 0,
    clubName: '',
    distance: meetingLocation ? '' : '25',
    distanceUnits: '',
  };
  const initialValuesfromURL = getClubParamsFromURL();
  const languages = data?.map((language: { id: string }) => language.id);
  const validatedInitialValues = validateClubParamsFromURL(
    initialValuesfromURL,
    languages
  );
  const handleSubmit = async (
    values: ClubSearchFormValues,
    helpers: FormikHelpers<ClubSearchFormValues>
  ) => {
    setDragged(false);
    setResetFlag(false);

    // clears Rotaract Type field if non-Rotaract Club Type is selected
    const organizationBase = getOrganizationBase({
      clubType: values.clubType,
      organizationBase: values.organizationBase,
    }) as OrganizationBase;

    const formValues = await withLocationKeywordFallback(values);
    const meetingLocationRange = getMeetingLocationRange(
      {
        distance: formValues.distance,
        distanceUnits: formValues.distanceUnits || 'km',
      },
      initialValues.distance
    );

    helpers.setFieldValue('meetingLocationRange', meetingLocationRange);
    helpers.setFieldValue('organizationBase', organizationBase);

    const validFormValues = validateClubParamsFromURL(
      {
        ...formValues,
        meetingLocationRange,
        organizationBase,
      },
      languages
    );
    onSubmit(validFormValues);
    refreshSearch(values);
    helpers.setSubmitting(false);

    const appUrl = process.env.MRX_APP_PUBLIC_URL ?? '';
    const baseClubURL = `${appUrl}${addLocalisationToPath(
      '/domui/club-search'
    )}`;
    const formattedURL = addQueryParamsToURL(baseClubURL, validFormValues);
    window.history.pushState({}, '', formattedURL);
  };

  return (
    <Formik
      initialValues={
        isEmpty(validatedInitialValues) ? initialValues : validatedInitialValues
      }
      onSubmit={handleSubmit}
    >
      {children}
    </Formik>
  );
};

export default ClubSearchFormik;
