import React, { useEffect } from 'react';

import { Form, useFormikContext } from 'formik';
import { ary } from 'lodash';

import { Button } from '@components/Button';
import ClubLocationSelect from '@components/Formik/LocationSelector/ClubLocationSelect';
import RotaractTypeSelect from '@components/Formik/Select/RotaractTypeSelect';
import WeekdaySelect from '@components/Formik/Select/WeekdaySelect';
import TextField from '@components/Formik/TextField';

import { getFilterSelectPlaceholder } from '@use-cases/search/helpers';

import { addLocalisationToPath } from '@utils/localized-navigate';

import ClubTypeSelect from '@domui-components/Formik/Select/ClubTypeSelect';
import DistanceRangeSelect from '@domui-components/Formik/Select/DistanceRangeSelect';
import LanguageSelect from '@domui-components/Formik/Select/LanguageSelect';
import MeetingTypeSelect from '@domui-components/Formik/Select/MeetingTypeSelect';
import { ClubSearchFormValues } from '@domui-domain/clubs';
import { DynamicData } from '@domui-domain/type';
import {
  isClubRotaract,
  isClubRotaractSatellite,
} from '@domui-use-cases/clubs';

import { useTranslation } from '@external/react-i18next';

type Props = {
  handleReset?: () => void;
  handleLocation?: (location: string) => void;
  languages: DynamicData;
  languagesLoading: boolean;
};

const ClubSearchForm: React.FC<Props> = ({
  handleReset,
  handleLocation,
  languages,
  languagesLoading,
}) => {
  const { t } = useTranslation();
  const { isSubmitting, resetForm, values, setFieldValue } = useFormikContext<
    ClubSearchFormValues
  >();

  const { clubType } = values;
  const isRotaract = isClubRotaract(clubType || '');
  const isRotaractSatellite = isClubRotaractSatellite(clubType || '');

  const meetingLocation = values.meetingLocation?.trim() || '';

  useEffect(() => {
    if (meetingLocation) {
      handleLocation?.(meetingLocation);
    }
  }, [values.meetingLocation]);

  const clubResetFilter = () => {
    resetForm({
      values: {
        meetingLocationRange: 0,
        clubName: '',
        distance: '25',
        distanceUnits: '',
      },
    });
    handleReset?.();
    setFieldValue('distance', '25');
    sessionStorage.removeItem('search');
    const appUrl = process.env.MRX_APP_PUBLIC_URL ?? '';
    const clubSearchURL = `${appUrl}${addLocalisationToPath(
      '/domui/club-search'
    )}`;
    window.history.pushState({}, '', clubSearchURL);
  };

  useEffect(() => {
    if (window.location.search === '') {
      resetForm();
      handleReset?.();
      setFieldValue('distance', '25');
      sessionStorage.removeItem('search');
    }
  }, [window.location.search]);

  return (
    <>
      <h2 className="mb-8">{t('search.clubs.filters-title', 'Find a club')}</h2>
      <Form onReset={ary(clubResetFilter, 0)}>
        <TextField
          name="clubName"
          label={t('club-search.form.name.label', 'Club Name')}
          placeholder={t(
            'club-search.form.name.placeholder',
            'Find by name (optional)'
          )}
          resettable
          icon="magnifier"
        />
        <h4 className="mt-10 mb-5 alternate">
          {t('club-search.form.filters-heading', 'Filter by')}
        </h4>
        <ClubLocationSelect
          name="meetingLocation"
          label={t('club-search.form.location.label', 'Meeting Location')}
          resettable
        />
        <DistanceRangeSelect meetingLocation={meetingLocation} />
        <ClubTypeSelect
          name="clubType"
          searchable
          clearable
          label={t('search.clubs.filters-clubType-label', 'Club Type')}
          placeholder={getFilterSelectPlaceholder(t)}
        />
        {(isRotaract || isRotaractSatellite) && (
          <RotaractTypeSelect
            name="organizationBase"
            searchable
            clearable
            label={t(
              'search.clubs.filters-rotaractType-label',
              'Rotaract Type'
            )}
            placeholder={getFilterSelectPlaceholder(t)}
          />
        )}
        <WeekdaySelect
          name="weekday"
          searchable
          clearable
          label={t('search.clubs.filters-weekday-label', 'Meeting Day')}
          placeholder={getFilterSelectPlaceholder(t)}
        />
        <MeetingTypeSelect
          name="meetingType"
          searchable
          clearable
          label={t('search.clubs.filters-meetingType-label', 'Meeting Type')}
          placeholder={getFilterSelectPlaceholder(t)}
        />
        <LanguageSelect
          name="meetingLang"
          clearable
          label={t(
            'search.clubs.filters-meetingLang-label',
            'Meeting Language'
          )}
          placeholder={getFilterSelectPlaceholder(t)}
          languages={languages}
          languagesLoading={languagesLoading}
        />
        <Button full disabled={isSubmitting} className="mt-4">
          {t('search.clubs.submit-label', 'Search')}
        </Button>
        <Button type="reset" textNormal capitalize full className="mt-6 center">
          {t('search.clubs.reset-label', 'Reset all filters')}
        </Button>
      </Form>
    </>
  );
};

export default ClubSearchForm;
