import {
  DISTANCE_MI,
  earthMeanRadiusKm,
  milesToKmRate,
} from '@domain/search/constants';

import { ClubSearchFormValues } from '@domui-domain/clubs/types';

export interface EarthCoordinates {
  latitude: number;
  longitude: number;
}

const radiansPerDegree = Math.PI / 180;
const degreesToRadians = (degrees: number): number =>
  degrees * radiansPerDegree;
const haversine = (a: EarthCoordinates, b: EarthCoordinates): number =>
  Math.sin((degreesToRadians(b.latitude) - degreesToRadians(a.latitude)) / 2) **
    2 +
  Math.cos(degreesToRadians(a.latitude)) *
    Math.cos(degreesToRadians(b.latitude)) *
    Math.sin(
      (degreesToRadians(b.longitude) - degreesToRadians(a.longitude)) / 2
    ) **
      2;
const archaversine = (haversine: number): number =>
  Math.asin(Math.sqrt(haversine)) * 2;

export const earthDistanceKm = (
  a: EarthCoordinates,
  b: EarthCoordinates
): number => archaversine(haversine(a, b)) * earthMeanRadiusKm;
export const mercatorStretchFactor = (latitude: number): number =>
  1 / Math.cos(degreesToRadians(latitude));

export const getKmFromMiles = (miles: number) => miles * milesToKmRate;

export const getMeetingLocationRange = (
  { distance, distanceUnits }: ClubSearchFormValues,
  initialDistance?: string | undefined
) => {
  if (distance) {
    return distanceUnits === DISTANCE_MI
      ? getKmFromMiles(Number(distance))
      : Number(distance);
  }

  if (initialDistance && !distance && distanceUnits === DISTANCE_MI) {
    return getKmFromMiles(Number(initialDistance));
  }

  return 0;
};
