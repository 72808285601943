import React from 'react';

import Select, { SelectProps } from '../Select';

import { useTranslation } from '@external/react-i18next';

const ClubTypeSelect: React.FC<Omit<SelectProps, 'options'>> = props => {
  const { t } = useTranslation();

  const options = [
    {
      label: t('club-type.rotary-club', 'Rotary Club'),
      value: 'Rotary Club',
    },
    {
      label: t('club-type.satellite-club', 'Rotary Satellite Club'),
      value: 'Satellite Club',
    },
    {
      label: t('club-type.rotaract-club', 'Rotaract Club'),
      value: 'Rotaract Club',
    },
    {
      label: t('club-type.rotaract-satellite-club', 'Rotaract Satellite Club'),
      value: 'Rotaract Satellite Club',
    },
  ];

  return <Select {...props} searchable options={options} />;
};

export default ClubTypeSelect;
