import React from 'react';

import { Field, useFormikContext } from 'formik';

import { Radios } from '@components/Forms/Radios';

import {
  DISTANCE_KM,
  DISTANCE_MI,
  DISTANCE_OPTIONS,
} from '@domain/search/constants';

import Select, { SelectProps } from '@domui-components/Formik/Select';
import { ClubSearchFormValues } from '@domui-domain/clubs/types';

import { useTranslation } from '@external/react-i18next';

type DistanceSelectProps = React.FC<Omit<SelectProps, 'options'>>;

type DistanceRangeSelectProps = {
  meetingLocation: string;
};

const DistanceSelect: DistanceSelectProps = props => {
  const distanceSelectOptions = DISTANCE_OPTIONS.map(distance => ({
    label: String(distance),
    value: String(distance),
  }));
  return <Select options={distanceSelectOptions} {...props} />;
};

const DistanceRangeSelect: React.FC<DistanceRangeSelectProps> = ({
  meetingLocation,
}) => {
  const { t } = useTranslation();
  const { values } = useFormikContext<ClubSearchFormValues>();

  const unitOptions = [
    {
      label: t('search.clubs.filters-distance-km', 'Km'),
      value: DISTANCE_KM,
    },
    {
      label: t('search.clubs.filters-distance-mi', 'Miles'),
      value: DISTANCE_MI,
    },
  ];

  return (
    <div className="flex">
      <div className="w-1/2">
        <DistanceSelect
          name="distance"
          searchable
          disabled={!meetingLocation}
          label={t('search.clubs.filters-distance-label', 'Distance')}
        />
      </div>

      <Field
        component="div"
        className="flex w-1/2 flex-col-reverse"
        name="distance-units-radio-group"
      >
        <Radios
          name="distanceUnits"
          id="distanceUnits"
          value={values.distanceUnits || DISTANCE_KM}
          options={unitOptions}
          disabled={!meetingLocation}
          noMargins
          classes={{
            optionsWrapper: 'flex justify-evenly mb-13/2',
          }}
        />
      </Field>
    </div>
  );
};

export default DistanceRangeSelect;
