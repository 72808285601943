import React, { Dispatch, SetStateAction } from 'react';

import Link from '@components/Link';
import ClubMeetingIcon from '@presenters/web/pages/ClubSearch/ClubSearchList/ClubMeetingIcon';

// import { SearchClubNumberedMeetingFragment } from '@domain/clubs';
import { Meeting } from '../ClubSearchList';

import {
  // MEETING_TYPE_MEETING,
  MEETING_TYPE_ONLINE,
  MEETING_TYPE_PHYSICAL,
} from '@domain/search';

import {
  // getMeetingTypeLabel,
  getMeetingTypeTranslation,
} from '@use-cases/clubs';

import { getDayLabel } from '@utils/getDayLabel';

import { MEETING_TYPE_IN_PERSON } from '@domui-domain/search/constants';

import { useTranslation } from '@external/react-i18next';

interface ClubMeetingProps extends Meeting {
  setPinnedClub: Dispatch<SetStateAction<number | null>>;
  weekday: string;
  languageName: string;
  time: string;
  type: string;
  xRiLocationNumber: number | null;
}

const ClubMeeting: React.FC<ClubMeetingProps> = ({
  description,
  weekday,
  languageName,
  xRiLocationNumber,
  time,
  type,
  comment,
  url,
  setPinnedClub,
}) => {
  const { t } = useTranslation();

  // const meetingType: string =
  //   (categories && getMeetingTypeLabel(categories)) || MEETING_TYPE_MEETING;
  const localizedMeetingType = getMeetingTypeTranslation(t, type);

  const isOnline = type === MEETING_TYPE_ONLINE;
  const isPhysical =
    type === MEETING_TYPE_PHYSICAL || type === MEETING_TYPE_IN_PERSON;

  const clickHandler = () => {
    const googleContainer: HTMLDivElement | null = document.querySelector(
      '#googleMapContainer'
    );
    if (googleContainer) {
      googleContainer.scrollIntoView({ block: 'center' });
    }
    setPinnedClub(xRiLocationNumber || null);
  };

  return (
    <div data-label="SearchResult" className="flex w-full mt-4">
      <button type="button" onClick={clickHandler}>
        <ClubMeetingIcon
          online={isOnline}
          physical={isPhysical}
          locationNumber={xRiLocationNumber ?? undefined}
        />
      </button>
      <div className="self-stretch w-3/4">
        <p className="mb-0 text-small">
          {t('club-search.meetings.title', '{{title}}', {
            title: description,
          })}
        </p>
        <p className="mb-0 text-small">
          {t(
            'club-search.meetings.description-physical',
            'Meets {{ day }}, {{ time }}',
            {
              type: localizedMeetingType,
              day: getDayLabel(t, String(weekday)),
              time,
            }
          )}

          {languageName !== null &&
            t(
              'club-search.meetings.description-language-name',
              `, ${languageName}`
            )}
        </p>
        {url && !isPhysical ? (
          <p className="text-small">
            {t(
              'club-search.meetings.description-online-hybrid-link',
              '{{type}} at ',
              {
                type: localizedMeetingType,
              }
            )}
            <Link to={url}>{url}</Link>
          </p>
        ) : (
          ''
        )}
        {!url && comment && <p className="text-small">{comment}</p>}
      </div>
    </div>
  );
};
export default ClubMeeting;
