import React, { useEffect, useState } from 'react';

import { OneColumn } from '@components/Layouts/OneColumn';
import { TwoColumns } from '@components/Layouts/TwoColumns';
import { Submenu } from '@components/Submenu';
import Title from '@components/Title';

import ClubSearchForm from './ClubSearchForm';
import ClubSearchFormik from './ClubSearchFormik';
import ClubSearchList from './ClubSearchList';

import { ClubSearchFormValues } from '@domui-domain/clubs';
import { useFetchLanguages } from '@domui-hooks/useFetchLanguages';
import {
  getClubParamsFromURL,
  validateClubParamsFromURL,
} from '@domui-use-cases/districts';

import { useTranslation } from '@external/react-i18next';
import { useMenu } from '@hooks/menu';
import { useReset } from '@hooks/useReset';

const ClubSearch: React.FC = location => {
  const { t, i18n } = useTranslation();
  const [filters, setFilters] = useState<ClubSearchFormValues>({});
  const [currentPage, setCurrentPage] = useState(1);
  const [dragged, setDragged] = useState(false);
  const [enteredLocation, setEnteredLocation] = useState('');
  const { resetFlag, setResetFlag, handleReset } = useReset();
  const {
    data: submenuData,
    error: submenuError,
    loading: submenuLoading,
  } = useMenu('menu-my-rotary-search-navigation', i18n.language);

  const handleLocation = (location: string) => {
    setEnteredLocation(location);
  };
  const {
    data: languages,
    loading: languagesLoading,
    fetchLanguages,
  } = useFetchLanguages();

  useEffect(() => {
    fetchLanguages();
  }, []);

  useEffect(() => {
    setCurrentPage(1);
  }, [filters]);

  useEffect(() => {
    const filtersFromURL = getClubParamsFromURL();
    const validatedFilters = validateClubParamsFromURL(filtersFromURL);
    setFilters({ ...filters, ...validatedFilters });
  }, []);

  useEffect(() => {
    if (window.location?.search === '') {
      setFilters({});
    }
  }, [location]);

  return (
    <>
      <OneColumn>
        <Title headTitle={t('metadata.title.search-club', 'Club Search')}>
          {t('search.title', 'Search')}
        </Title>
        {!submenuLoading && !submenuError && !!submenuData.length && (
          <Submenu items={submenuData} location={location} />
        )}
      </OneColumn>
      <ClubSearchFormik
        onSubmit={setFilters}
        setDragged={setDragged}
        setResetFlag={setResetFlag}
        meetingLocation={enteredLocation}
      >
        <TwoColumns
          className="search-page"
          bigColumnClassName="pt-0 pb-12 px-0 desktop-l:pl-0"
          small={
            <ClubSearchForm
              handleReset={handleReset}
              handleLocation={handleLocation}
              languages={languages}
              languagesLoading={languagesLoading}
            />
          }
          big={
            <ClubSearchList
              dragged={dragged}
              setDragged={setDragged}
              filters={filters}
              resetFlag={resetFlag}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              languages={languages}
            />
          }
        />
      </ClubSearchFormik>
    </>
  );
};

export default ClubSearch;
