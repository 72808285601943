import React, { Dispatch, SetStateAction } from 'react';

import Link from '@components/Link';

import ClubMeeting from '../ClubMeeting';
import { Meeting } from '../ClubSearchList';

import { MEETING_TYPE_ONLINE } from '@domain/search';

import { trimClubFullName } from '@utils/club-name';

import { getClubTypeName } from '@domui-utils/getClubTypeName';

import { useTranslation } from '@external/react-i18next';
import { useAnalytics } from '@hooks/analytics';

import { DIS } from '@typings/dis';
import { Enum, From, mapEnum } from '@typings/map-enum';

type ClubSearchResultProps = {
  searchRank: number;
  fullName: string;
  name: string;
  type: string;
  id: string;
  meetings: Meeting[];
  setPinnedClub: Dispatch<SetStateAction<number | null>>;
};

const ClubSearchResult: React.FC<ClubSearchResultProps> = ({
  fullName,
  type,
  meetings,
  id,
  searchRank,
  setPinnedClub,
}) => {
  const { fireTrackingEvent } = useAnalytics();
  const { t } = useTranslation();
  const clubPath = `/domui/club/${id}/details`;
  const clubMeeting = meetings
    ? meetings
        .filter(meeting => meeting?.weekday && meeting?.time)
        .map((meeting, index) => {
          const meetingKey = `${meeting?.latitude}-${meeting?.longitude}-${index}`;
          return (
            <ClubMeeting
              key={meetingKey}
              setPinnedClub={setPinnedClub}
              {...meeting}
              xRiLocationNumber={meeting.xRiLocationNumber ?? null}
            />
          );
        })
    : [];

  const firstMeeting = meetings?.find(
    meeting => meeting.city || meeting.state || meeting.country
  );

  // const { xRiAddress, categories } = firstMeeting || {
  //   xRiAddress: null,
  //   categories: null,
  // };

  const ClubNameType = type
    ? getClubTypeName(t, mapEnum(From.DIS, Enum.ClubType, type as DIS.ClubType))
    : '';

  const fullNameWithType = `${trimClubFullName(fullName)} (${ClubNameType})`;

  const city = firstMeeting?.city || '';
  const state = firstMeeting?.state ? `, ${firstMeeting.state}` : '';
  const country = firstMeeting?.country ? `, ${firstMeeting.country}` : '';

  const isOnline = type ? type === MEETING_TYPE_ONLINE : false;

  const trackClick = (destination: string) => {
    fireTrackingEvent('searchResultClicked', {
      searchResultDestination: destination,
      searchType: 'Club Search',
      searchResultIndex: searchRank,
    });
    sessionStorage.setItem('search', 'true');
  };

  return (
    <div data-label="SearchResult" className="flex w-full">
      <div className="self-stretch">
        <div className="ml-6">
          <h3 className="mb-2">
            <Link
              role="link"
              to={clubPath}
              onClick={() => trackClick(clubPath)}
            >
              {fullNameWithType}
            </Link>
          </h3>

          {firstMeeting?.weekday && firstMeeting?.time && !isOnline && (
            <p className="text-small font-bold">{`${city}${state}${country}`}</p>
          )}
        </div>
        {clubMeeting}
      </div>
    </div>
  );
};

export default ClubSearchResult;
